<template>
    <div class="surface-0 flex align-items-center justify-content-center min-h-screen min-w-screen overflow-hidden">
        <loading v-model:active="loader.isLoading" :can-cancel="false" :is-full-page="true" color="#0C0C0C" :width="loader.loaderSize" :height="loader.loaderSize" :opacity="loader.opacity" />
        <Dialog v-model:visible="showMessage" header=" " :breakpoints="{ '960px': '80vw' }" :style="{ width: '30vw' }" position="top">
            <div class="flex align-items-center flex-column pt-6 px-3">
                <i class="pi pi-times-circle" :style="{ fontSize: '5rem', color: 'var(--orange-900)' }"></i>
                <h5>Error</h5>
                <p :style="{ lineHeight: 1.5, textIndent: '1rem' }">Dirección de email o contraseña incorrectos.</p>
            </div>
            <template #footer>
                <div class="flex justify-content-center">
                    <Button label="OK" class="p-button-text" @click="toggleDialog" />
                </div>
            </template>
        </Dialog>

        <div class="grid justify-content-center p-2 lg:p-0" style="min-width: 80%">
            <div class="col-12 xl:col-6" style="border-radius: 56px; padding: 0.3rem; background: linear-gradient(180deg, rgba(70, 150, 160, 0.7) 10%, rgba(70, 150, 160, 0) 30%)">
                <div class="h-full w-full m-0 py-7 px-4" style="border-radius: 53px; background: linear-gradient(180deg, var(--surface-50) 38.9%, var(--surface-0))">
                    <Button class="p-button-danger p-button-rounded p-button-text" icon="pi pi-times" style="top: -2rem !important" :aria-label="$t('accesibility.login.return')" @click="toggleLanding"></Button>
                    <div class="grid flex flex-column align-items-center">
                        <div class="flex justify-content-center align-items-center border-circle" style="width: 3.2rem; height: 3.2rem">
                            <img src="/images/Logo_148x158N.svg" alt="Bemyvega logo" class="mb-5" style="width: 81px; height: 60px" aria-hidden="true" />
                        </div>
                        <h1 class="text-900 font-bold text-4xl lg:text-5xl mb-2">
                            {{ $t("endpoints.login.header") }}
                        </h1>
                    </div>

                    <form ref="loginForm" class="p-fluid" aria-describedby="Introduce tus datos" @submit.prevent="handleSubmit(!v$.$invalid)">
                        <div class="field" :aria-label="$t('accesibility.login.email')">
                            <div class="p-float-label p-input-icon-right mt-5">
                                <i aria-hidden="true" class="pi pi-envelope" />
                                <InputText id="email" v-model="v$.email.$model" :class="{ 'p-invalid': v$.email.$invalid && submitted }" aria-describedby="email-error" class="p-inputtext-lg" />
                                <label for="email" :class="{ 'p-error': v$.email.$invalid && submitted }">{{ $t("endpoints.login.body.email") }}</label>
                            </div>
                            <span v-if="v$.email.$error && submitted">
                                <span v-for="(error, index) of v$.email.$errors" id="email-error" :key="index">
                                    <small class="p-error">{{ error.$message }}</small>
                                </span>
                            </span>
                            <small v-else-if="(v$.email.$invalid && submitted) || v$.email.$pending.$response" class="p-error">{{ v$.email.required.$message.replace("Value", "Email") }}</small>
                        </div>

                        <div class="field" :aria-label="$t('accesibility.login.pass')">
                            <div class="p-float-label p-input-icon-right mt-5">
                                <i aria-hidden="true" class="pi pi-key" />
                                <InputText id="password" v-model="v$.password.$model" type="password" :class="{ 'p-invalid': v$.password.$invalid && submitted }" toggle-mask class="p-inputtext-lg"> </InputText>
                                <label for="password" :class="{ 'p-error': v$.password.$invalid && submitted }">{{ $t("endpoints.login.body.password") }}</label>
                            </div>
                            <small v-if="(v$.password.$invalid && submitted) || v$.password.$pending.$response" class="p-error">{{ v$.password.required.$message.replace("Value", "Password") }}</small>
                        </div>

                        <Button :label="$t('endpoints.login.body.button')" class="w-full p-3 text-xl mt-1" style="background: #0c0c0c" type="submit" aria-describedby="Enviar datos" :aria-label="$t('accesibility.login.login')"></Button>

                        <div id="login-links" class="flex justify-content-between mt-4">
                            <div aria-describedby="Ir a la página de registro" :aria-label="$t('accesibility.login.register')" role="none">
                                <router-link id="not-registered" to="/register" style="color: var(--primary-color)" :aria-label="$t('accesibility.login.goregister')">
                                    {{ $t("endpoints.login.body.redirect") }}
                                </router-link>
                            </div>
                            <div aria-describedby="Recuperar contraseña" :aria-label="$t('accesibility.login.forgot-password')" role="none">
                                <router-link id="forgot-password" to="/forgot-password" style="color: var(--primary-color)" :aria-label="$t('accesibility.login.forgot-password')">
                                    {{ $t("endpoints.login.body.forgot-password") }}
                                </router-link>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

import { email, required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import { setAuthToken, getUserInfo, isLoggedIn } from "../utils/auth";
import { RepositoryFactory } from "../repositories/RepositoryFactory";
import { mapState } from "vuex";

const LoginRepository = RepositoryFactory.get("login");

export default {
    components: {
        Loading
    },
    setup: () => ({ v$: useVuelidate() }),
    data() {
        return {
            email: "",
            password: "",
            checked: false,
            submitted: false,
            showMessage: false
        };
    },
    validations() {
        return {
            email: {
                required,
                email
            },
            password: {
                required
            }
        };
    },
    computed: {
        ...mapState(["loader"]),
        logoColor() {
            if (this.$appState.darkTheme) return "white";
            return "dark";
        }
    },
    created() {
        document.title = this.$t("accesibility.title.login");
    },
    async mounted() {
        let prefillData = this.$route.query.prefill;
        if (prefillData) {
            await this.loadPrefilledData();
            this.$refs.loginForm.dispatchEvent(new Event('submit', { cancelable: true }));
        }
    },
    methods: {
        async loadPrefilledData() {
            let prefillData = this.$route.query.prefill;
            if (prefillData) {
                let decodedData = JSON.parse(atob(prefillData));
                this.email = decodedData.email;
                this.password = decodedData.password;
            }
        },
        async login() {
            let formData = new FormData();
            formData.append("username", this.email);
            formData.append("password", this.password);

            try {
                let { data } = await LoginRepository.login(formData);
                setAuthToken(data["access_token"]);
                let { user_mode } = getUserInfo();
                
                // Redirigir y recargar la página inmediatamente después del inicio de sesión
                if (user_mode == "teacher") {
                    window.location.href = "/rooms";
                } else if (user_mode == "student") {
                    window.location.href = "/stream/EtUnrCFZykUU3WYUkFo2Yd?roomName=RuedadePrensaAccesibleAyuntamientodeMurcia";
                }

            } catch (error) {
                this.toggleDialog();
            }
        },
        handleSubmit(isFormValid) {
            this.submitted = true;

            if (!isFormValid) {
                return;
            }

            this.login();
        },
        toggleLanding() {
            this.$router.push("/");
        },
        toggleRegister() {
            this.$router.push("/register");
        },
        toggleDialog() {
            this.showMessage = !this.showMessage;

            if (!this.showMessage) {
                this.resetForm();
            }
        },
        resetForm() {
            this.email = "";
            this.password = "";
            this.submitted = false;
        },
        loadUserData() {
            let userInfo = getUserInfo();
            this.email = userInfo.email;
        }
    }
};
</script>


<style scoped>
.pi-eye {
    transform: scale(1.6);
    margin-right: 1rem;
}

.pi-eye-slash {
    transform: scale(1.6);
    margin-right: 1rem;
}
</style>
