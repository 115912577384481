import { createRouter, createWebHistory } from "vue-router";

import { getAuthToken, isLoggedIn } from "./utils/auth";
import { RepositoryFactory } from "./repositories/RepositoryFactory";
import api from "./repositories/Repository";

const LoginRepository = RepositoryFactory.get("login");

const routes = [
    /* This redirection is neccessary as it prevents Vue 
    from errors when trying to access "/" */

    {
        path: "/",
        name: "redirection",
        redirect: "/ ",
        meta: {
            allowAnonymous: true
        }
    },
    {
        path: "/ ",
        name: "landing",
        component: () => import("./pages/LandingDemo.vue"),
        meta: {
            allowAnonymous: true
        }
    },
    {
        path: "/recordings",
        name: "recordings",
        props: true,
        component: () => import("./components/Recordings.vue")
    },
    {
        path: "/meet-recordings",
        props: true,
        component: () => import("./pages/MeetRecordings.vue")
    },
    {
        path: "/stream/:id",
        name: "stream",
        component: () => import("./pages/StreamWrapper.vue")
    },
    // {
    //     path: "/media/:id",
    //     name: "media",
    //     component: () => import("./components/Media.vue")
    // },
    {
        path: "/diferido/:id/:recording",
        name: "diferido",
        component: () => import("./components/Diferido.vue")
    },
    {
        path: "/vod/:id/:vod",
        name: "vod",
        component: () => import("./pages/Vod.vue")
    },
    {
        path: "/menu",
        component: () => import("./components/MenuDemo.vue"),
        children: [
            {
                path: "",
                component: () => import("./components/menu/PersonalDemo.vue")
            },
            {
                path: "/menu/seat",
                component: () => import("./components/menu/SeatDemo.vue")
            },
            {
                path: "/menu/payment",
                component: () => import("./components/menu/PaymentDemo.vue")
            },
            {
                path: "/menu/confirmation",
                component: () => import("./components/menu/ConfirmationDemo.vue")
            }
        ]
    },
    {
        path: "/login",
        name: "login",
        component: () => import("./pages/Login.vue"),
        meta: {
            allowAnonymous: true
        }
    },
    {
        path: "/register",
        name: "register",
        component: () => import("./pages/Register.vue"),
        meta: {
            allowAnonymous: true
        }
    },
    {
        path: "/forgot-password",
        name: "forgotPassword",
        component: () => import("./pages/ForgotPassword.vue"),
        meta: {
            allowAnonymous: true
        }
    },
    {
        path: "/error",
        name: "error",
        component: () => import("./pages/Error.vue"),
        meta: {
            allowAnonymous: true
        }
    },
    {
        path: "/access",
        name: "access",
        component: () => import("./pages/Access.vue"),
        meta: {
            allowAnonymous: true
        }
    },
    {
        path: "/rooms",
        name: "rooms",
        component: () => import("./pages/Rooms.vue")
    },
    {
        path: "/sessions",
        name: "sessions",
        component: () => import("./pages/Sessions.vue")
    },
    // {
    //     path: "/categories",
    //     name: "categories",
    //     component: () => import("./components/Categories.vue")
    // },
    {
        path: "/profile",
        name: "profile",
        component: () => import("./components/Profile.vue")
    },
    // {
    //     path: "/test-vod",
    //     name: "test-vod",
    //     component: () => import("./pages/TestVOD.vue")
    // },
    // {
    //     path: "/test-streaming",
    //     name: "test-streaming",
    //     component: () => import("./pages/TestStreaming.vue")
    // },
    {
        path: "/demo",
        component: () => import("./pages/demos/Demo.vue")
    },
    {
        path: "/demo/streaming",
        component: () => import("./pages/demos/StreamingDemo.vue")
    },
    {
        path: "/demo/vod",
        component: () => import("./pages/demos/VodDemo.vue")
    },
    {
        path: "/demo/bidirectional",
        component: () => import("./pages/demos/BidirectionalDemo.vue")
    },
    {
        path: "/demo/test",
        component: () => import("./pages/demos/TestDemo.vue")
    },
    {
        path: "/code-access",
        component: () => import("./pages/CodeAccess.vue")
    },
    {
        path: "/notfound",
        name: "notfound",
        component: () => import("./pages/NotFound.vue"),
        meta: {
            allowAnonymous: true
        }
    },
    {
        path: "/:pathMatch(.*)*",
        name: "default",
        redirect: "/notfound"
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes
});

router.beforeEach((to, from, next) => {
    // Verificar si el usuario está intentando acceder a la sesión específica y si necesita pre-llenado
    const openSessionPath = '/stream/584cb8da-9d97-464f-b933-037467fabec9';

    if (to.path === openSessionPath) {
        if (!isLoggedIn()) {
            // Usuario no está logueado, redirige con datos pre-llenados
            let prefillData = btoa(JSON.stringify({ email: 'asistente@ruedaprensajgl.com', password: '+Admin24' }));
            next({ path: '/login', query: { prefill: prefillData } });
        } else {
            // Usuario ya está logueado, permite acceso directo
            next();
        }
    } else {
        // Continuar con la lógica de autorización original
        if (to.meta.allowAnonymous) {
            delete api.defaults.headers.common["Authorization"];
            next();
        } else if (!to.meta.allowAnonymous && isLoggedIn()) {
            api.defaults.headers.common["Authorization"] = "Bearer " + getAuthToken();
            LoginRepository.checkToken().then(() => {
                next();
            }).catch(() => {
                next('/login'); // Si el token no es válido, redirige a login
            });
        } else {
            next("/access"); // Redirige a la página de acceso si no está autenticado
        }
    }
});

export default router;
